import React from 'react'
import Img from 'gatsby-image'
import { css } from 'theme-ui'
import { buildResponsiveVariant as rv } from '../utils'

const CardMediaImage = ({ variant, loading, image, alt }) => (
  <Img
    fluid={image}
    loading={loading}
    fadeIn={loading === 'lazy' ? true : false}
    css={css({
      height: `full`,
      bg: `white`,
      variant: rv(variant, 'image')
    })}
    alt={alt}
  />
)
CardMediaImage.defaultProps = {
  loading: 'lazy'
}

export default CardMediaImage
