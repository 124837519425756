import React from 'react'
import { Text, useThemeUI, get } from 'theme-ui'
import { buildResponsiveVariant as rv } from '../utils'

const styles = {
  excerpt: {
    flex: `auto`,
    mb: 3
  }
}

const CardBodyExcerpt = ({ variant, excerpt, shortDescription, context, minimumWallet, timeToSpend, omitExcerpt }) => {
  const context2 = useThemeUI()

  const responsiveVariant = rv(variant, 'excerpt')

  const visibility = responsiveVariant.reduce(
    (mobileVisibility, variant) =>
      mobileVisibility === false &&
      get(context2.theme, variant, {}).display === 'none'
        ? false
        : true,
    false
  )

  return !omitExcerpt && visibility ? (
    <Text
      variant='h6'
      sx={{
        ...styles.excerpt,
        variant: responsiveVariant
      }}
    >
      {excerpt || shortDescription}<br/><br/>
      {shortDescription && (
          <>
            <p style={{margin:0}}><u><b>Objectif visé:</b></u> {context}</p>

            <p style={{margin:0}}><u><b>Capital requis:</b></u> {minimumWallet}</p>

            <p style={{margin:0}}><u><b>Temps d'exécution:</b></u> {timeToSpend}</p>
          </>
      )}
    </Text>
  ) : null
}

export default CardBodyExcerpt
